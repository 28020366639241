<template>
  <footer class="container-fluid">
    <div class="container">
      <div class="row text-center">
        <div class="col-md-6">
          <a href="http://codicedimigrazione.it" target="_blank"
            >CODICE DI MIGRAZIONE</a
          >
        </div>
        <div class="col-md-6">
          <a href="http://verificalacopertura.it" target="_blank"
            >VERIFICA LA COPERTURA</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "PgFooter",
};
</script>

<style scoped>
@import url("../../assets/css/footer.css");
</style>
