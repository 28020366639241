<template>
  <button class="btn btn-default up-btn" @click="goUp">
    <i class="fas fa-angle-up"></i>
  </button>
</template>

<script>
const JQuery = require("jquery/dist/jquery.min.js");
export default {
  name: "UpBtn",
  methods: {
    goUp() {
      JQuery("html, body").animate({ scrollTop: 0 }, 1000);
    },
  },
};
</script>

<style scoped>
.up-btn {
  position: fixed;
  right: 4%;
  bottom: 5%;
  border: 0px;
  outline: none;
  box-shadow: none;
  background-color: rgba(200, 200, 200, 0.6);
}

.up-btn:hover {
  background-color: rgba(200, 200, 200, 0.9);
}

.up-btn:focus {
  border: 0px;
  outline: none;
  box-shadow: none;
}
</style>
