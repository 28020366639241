<template>
  <header>
    <div class="container">
      <!--menu button-->
      <menu-icon :menu-id="idMenu" icon-id="idIcon"></menu-icon>

      <div class="header__logo">
        <router-link to="/">
          <img
            :src="require('../../assets/imgs/codicedimigrazione_logo.png')"
            class="d-none d-lg-block img-responsive header__logo-img"
            alt="verifica codice di migrazione codice segreto"
          />
        </router-link>

        <router-link to="/">
          <img
            :src="
              require('../../assets/imgs/codicedimigrazione_logo-white.png')
            "
            class="d-lg-none d-block img-responsive header__logo-img"
            alt="verifica codice di migrazione codice segreto"
          />
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
import MenuIcon from "../menu/Menu_icon.vue";

export default {
  name: "PgHeader",

  components: { MenuIcon },

  props: ["idMenu", "idIcon"],
};
</script>

<style scoped>
@import url("../../assets/css/header.css");
</style>
