import Vue from "vue";
import VueRouter from "vue-router";
const HomePage = () => import("./routes/HomePage.vue");
const ResultPage = () => import("./routes/ResultPage.vue");
const SuccessPage = () => import("./routes/SuccessPage.vue");
const PrivacyPolicyPage = () => import("./routes/PrivacyPolicyPage.vue");

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    { path: "/", name: "home", component: HomePage },
    { path: "/result", name: "result", component: ResultPage },
    { path: "/success", name: "leadSuccess", component: SuccessPage },
    {
      path: "/privacy-policy",
      name: "privacyPolicy",
      component: PrivacyPolicyPage,
    },
    { path: "/privacy-policy.html", redirect: { name: "privacyPolicy" } },
  ],

  mode: "hash",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
