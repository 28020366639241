import Vue from "vue";
import Router from "./routes.js";
import VueResource from "vue-resource";
import App from "./App.vue";

import "./assets/css/app.css";

import "bootstrap/dist/js/bootstrap.min.js";
import "lightbox2/dist/css/lightbox.css";
import lightbox from "lightbox2/dist/js/lightbox.min.js";

lightbox.option({
  positionFromTop: 30,
  maxWidth: 1200,
  showImageNumberLabel: false,
});

Vue.config.productionTip = false;

Vue.use(VueResource);

new Vue({
  render: (h) => h(App),
  router: Router,
}).$mount("#app");
