<template>
  <div>
    <pg-header :idIcon="menu.icon" :idMenu="menu.id"></pg-header>

    <router-view></router-view>

    <pg-footer></pg-footer>
    <up-btn></up-btn>
  </div>
</template>

<script>
import PgHeader from "./components/header/PgHeader.vue";
import PgFooter from "./components/footer/PgFooter.vue";
import UpBtn from "./components/buttons/UpButton.vue";

export default {
  name: "app",

  components: { PgHeader, PgFooter, UpBtn },

  data() {
    return {
      menu: {
        id: "menu",
        icon: "menu-icon",
      },
    };
  },
};
</script>
