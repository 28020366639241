<template>
  <div>
    <button
      class="menu-icon d-lg-none"
      :id="iconId"
      :class="{ change: close }"
      @click="toggle_menu"
    >
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </button>
  </div>
</template>

<script>
import JQuery from "jquery";

export default {
  name: "MenuIcon",

  data: function () {
    return {
      close: false,
    };
  },

  props: ["menuId", "iconId"],

  methods: {
    toggle_menu: function () {
      //menu element
      const menu = JQuery(`#${this.menuId}`);

      if (menu.css("display") !== "none") {
        this.close = false;
        menu.slideUp();
      } else {
        this.close = true;
        menu.slideDown();
      }
    },
  },
};
</script>

<style scoped>
@import url("../../assets/css/menu/menu_icon.css");
</style>
